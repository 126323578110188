import React from 'react';
import BaseHeader from "src/components/Header";
import { PAGE_URLS } from "src/constants/urls";

type Props = {
    user: any;
    logout: () => void;
    manageProfile: () => void;
};

const Header = ({user, logout, manageProfile}: Props) => {
    return (
        <BaseHeader
            user={user}
            isAuthenticated
            logout={logout}
            home={PAGE_URLS.ADMIN}
            className="account-header"
            manageProfile={manageProfile}
        >
        </BaseHeader>
    );
};

export default Header;
