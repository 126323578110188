import styled, { css } from "src/styles/styled-components";
import Colors from "src/components/Colors";
import { Navbar} from "react-bootstrap";
import { BREAKPOINTS, mediaBreakpointUp } from "src/styles/mixins";

export const HeaderContainer = styled.div`
    &.active {
      visibility: visible;
      //transition: all 200ms ease-in;
    }
    
    &.disabled {
      visibility: hidden;
      //transition: all 200ms ease-out;
      transform: translate(0, -100%);
    }

    &.static {
      .navbar {
        position: absolute;
      }
    }
`;

export const StyledNav = styled(Navbar)`
  padding: 10px 15px;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  right: 0;
  align-items: center;
  background: ${Colors.white};

  .navbar-brand {
    z-index: 1;
    padding: 0 !important;

    img {
      top: 10px;
      left: 10px;
      width: auto;
      height: 80px;
      position: absolute;
      display: inline-block;
    }
  }

  .navbar-nav {
    margin-left: auto;
    .nav-link {
      font-size: 15px;
      line-height: 20px;
      margin: 0 15px;
      color: ${Colors.black};
      padding: 10px 0px !important;

      &.active {
        border-bottom: 4px solid ${Colors.black};
      }

      :hover {
        text-decoration: none;
        border-bottom: 4px solid ${Colors.black};
      }

      &.nav-btn {
        width: 100%;
        margin: 5px;
        white-space: nowrap;
        display: inline-block;
        padding: 8px 20px !important;

        &.app-btn {
            height: 36px;
            padding: 8px 20px;
            font-weight: bold;
            line-height: 15px !important;

            &.app-btn-outline {
              &:hover, &.active {
                background: ${Colors.dark};
                color: ${Colors.white};
              }
            }
            
            &.app-btn-dark {
              color: ${Colors.white};
              border: 1px solid ${Colors.dark};

              &:hover, &.active {
                background: transparent;
                color: ${Colors.dark};
                border: 1px solid ${Colors.dark};
              }
            }
        }

        &.active {
          border-bottom: none;
        }

        :hover {
          border-bottom: none;
        }
      }

      a {
        color: inherit;
        text-decoration: none;
      }
    }
  }

  ${mediaBreakpointUp(BREAKPOINTS.lg, css`
    padding: 25px 70px;

    .navbar-brand {
      img {
        top: 20px;
        left: 70px;
        height: 115px;
      }
    }

    .navbar-nav {
      .nav-link {
        &.nav-btn {
            width: auto;
            margin-left: 15px;
        }
      }
    }
  `)}

  ${mediaBreakpointUp(BREAKPOINTS.xl, css`
    padding: 25px 150px;

    &.is-auth {
      padding: 25px 130px;
    }

    .navbar-brand {
      img {
        top: 28px;
        left: 130px;
      }
    }
  `)}

`;

export const AuthContainer = styled.div`
    display: flex;
    margin-left: auto;
    .notification {
      .dropdown-menu {
        padding: 0;
        min-width: 200px;

        .dropdown-menu-header {
          padding: 8px 10px;
          color: ${Colors.white};
          border-top-left-radius: .267rem;
          border-top-right-radius: .267rem;
          background: ${Colors.dark};
        }

        .dropdown-menu-footer {
          text-align: center;
          padding: 8px 10px;
          border-top: 1px solid #DFE3E7;
        }
      }
      
      button {
        position: relative;
        .badge {
          position: absolute;
          top: 15px;
          right: 5px;
        }

        &:after {
          border-color: transparent;
        }
      }
    }

    .user {
      button {
        display: flex;
        align-items: center;
        position: relative;

        span {
          font-size: 24px;
          line-height: 33px;
          display: none;
        }
      }

      .dropdown-menu {
        right: 0;
        border: none;
        text-align: right;
        font-size: 14px;
        line-height: 26px;
      }
    }

    ${mediaBreakpointUp(BREAKPOINTS.md, css`
      .user {
        button {
          span {
            display: inline-block
          }
        }
      }
    `)}
`;