import { PAGE_URLS as URLS } from "src/constants/urls";

const ADMIN_URL = URLS.ADMIN;

export const PAGE_URLS = {
  DASHBOARD: `${ADMIN_URL}`,
  USERS: `${ADMIN_URL}users/`,
  ADMINS: `${ADMIN_URL}admins/`,
  FEES: `${ADMIN_URL}fees/`,
  DOCUMENTS: `${ADMIN_URL}documents/`,
  PAGES: `${ADMIN_URL}template/`,
  LOGOUT: `${ADMIN_URL}logout/`,
};
