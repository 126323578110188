const Colors = {
  black: "#000000",
  white: "#ffffff",

  darkGray: "#2E2F34",
  cinnabar: "#e84d1c",
  columbiaBlue: "#9DCFFF",
  comet: "#5c6081",
  coral: "#F8724C",
  cosmicLatte: "#DCFFEA",
  deYork: "#81c784",
  dimGray: "#6C6C6C",
  dodgerBlue: "#0083ff",
  dodgerBlue2: "#2699FB",
  dodgerBlueLight: "#4f9eff",
  fern: "#66bb6a",
  fiord: "#4c5760",
  fiord2: "#556068",
  forgetMeNot: "#fff3e0",
  eclipse: "#3f3f3f",
  ghost: "#C6C6C7",
  ghostWhite: "#f1f1f5",
  goldenPoppy: "#f5c604",
  gullGrey: "#a5abaF",
  gullGrey2: "#A6B0B8",
  heather: "#a6b1ba",
  koromiko: "#ffb74d",
  lasPalmas: "#c3ce45",
  lavender: "#e3e3eb",
  lightGray: "#d3d3d3",
  lightningYellow: "#ffa726",
  limerick: "#8db321",
  linkWater: "#CDD4D9",
  lynch: "#697680",
  malibu: "#63C9EF",
  mayaBlue: "#80BDFF",
  mediumSeaGreen: "#39B36D",
  navyBlue: "#0356FC",
  oliveDrab: "#6c842e",
  orangePeel: "#ff9800",
  pattensBlue: "#dde2e6",
  pattensBlueLighter: "#cae5ff",
  raven: "#6A7680",
  roseWhite: "#fcede8",
  royalBlue: "#2D6AE8",
  snow: "#fafafa",
  solitude: "#b2bbc4",
  solitudeBlue: "#deebff",
  sunsetOrange: "#ef5350",
  sweetCorn: "#f7dc6b",
  twilightBlue: "#f9fbf4",
  abbey: "#565759",
  mineShaft: "#262626",
  graySuit: "#C3C3CF",
  silver: "#C6C6C6",
  mercury: "#E3E3E3",
  veryLightGrey: "#C9C9C9",
  zircon: "#F6FAFF",
  peach: "#FFEABF",
};

const toExport = {
  ...Colors,
  primary: "#FFEABF",
  secondary: "#F6FAFF",
  green: Colors.limerick,
  blue: Colors.dodgerBlue,
  red: Colors.cinnabar,
  light: Colors.heather,
  dark: Colors.darkGray,
}

export default toExport;
