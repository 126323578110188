import React, {useEffect} from "react";
import * as Styled from "./styles";
import Header from "../Header";

interface Props {
  children: any;
  className?: string;
}

const Page = ({ className = '', children }: Props) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  
  return (
    <Styled.Container className={className}>
      <Header />
      <div className="page-body">
        {children}
      </div>
    </Styled.Container>
  );
};

export default Page;
