import styled  from "src/styles/styled-components";
import Colors from "src/components/Colors";

export const HeaderContainer = styled.div`
`;

export const Notification = styled.div`
    .dropdown-menu {
        padding: 0;
        width: 300px;

        .dropdown-menu-header {
            padding: 8px 10px;
            color: ${Colors.white};
            border-top-left-radius: .267rem;
            border-top-right-radius: .267rem;
            background: ${Colors.dark};
        }

        .dropdown-item {
            padding-top: 8px;
            white-space: normal;
            border-top: 1px solid rgba(0,0,0,.2);
            .dropdown-item-heading {
                margin-bottom: .2rem;
                font-weight: 400;
                line-height: 1.2;
            }
        }

        .dropdown-menu-footer {
            text-align: center;
            padding: 8px 10px;
            border-top: 1px solid #DFE3E7;
        }
    }
    
    button {
        position: relative;
        .badge {
            position: absolute;
            top: 15px;
            right: 5px;
        }

        &:after {
            border-color: transparent;
        }
    }
`;