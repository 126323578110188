import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import * as Styled from "./styles";
import Header from "../Header";
import { BASE_URL } from "src/constants/urls";
import { PAGE_URLS } from "../../constants/urls";
import { NavLink as Link } from "react-router-dom";
import AccountActions, { IAccountReducer } from "../../../_redux/action";
import AppActions, { IAppReducer } from "src/redux/action";

interface Props {
  user: any;
  token: any;
  children: any;
  className?: string;

  history?: any;
  location?: any;
  match?: any;
  notifications: any[];

  me: () => void;
  logout: () => void;
  updateUser: (data: any) => void;
  addNotification: (data: any) => void;
  removeNotification: (index: number) => void;
}

interface State {
  isMinified: boolean;
}

const NavLink = ({to, icon, title, exact = false, location = '/'}: any) => {
  const isActive = location.pathname === to;
  return (
    <li>
      <Link exact={exact} to={to}>
        <img src={`${BASE_URL}img/${icon}${isActive? '_active': ''}.svg`} alt={title}/>
        <span>{title}</span>
      </Link>
    </li>
  )
};

class Page extends React.Component<Props, State> {
  public socket: any | undefined;

  public constructor(props: Props) {
    super(props);

    this.state = {
      isMinified: true,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    this.socket = (window as any).socket;
    if (this.socket) {
      this.socket.on("application:notify", (data: any) => {
        this.props.addNotification({
          message: data.message,
          createdAt: data.createdAt
        });

        if(!_.isEmpty(data.extra))
          this.props.updateUser(data.extra);
      });

      this.socket.on("fee:notify", (data: any) => {
        this.props.addNotification({
          message: data.message,
          createdAt: data.createdAt
        });

        if(!_.isEmpty(data.extra))
          this.props.updateUser(data.extra);
      });

      this.socket.on('general', (data: any) => {
        this.props.addNotification({
          message: data.message,
          createdAt: data.createdAt
        });
      });

      this.socket.on('connect', () => {
        this.socket.emit("im-in", this.props.user.id);
      });
    }

    this.props.me();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.location !== this.props.location) {
      this.setState({isMinified: true});
    }
  }

  componentWillUnmount() {
    // disconnect socket
    this.socket.disconnect();
  }

  render() {
    const { isMinified } = this.state;
    const { user, notifications, className = '', children, location } = this.props;
    
    return (
      <Styled.Container className={`${className} ${isMinified? 'is-minified': ''}`}>
        <Styled.Header className="page-header">
          <nav>
            <button
              className="toggle-mob-menu btn btn-light"
              aria-expanded="false"
              aria-label="open menu"
              onClick={() => this.setState({isMinified: !isMinified})}
            >
              <i className="fa fa-bars" />
            </button>

            <ul className="admin-menu">
              <NavLink exact to={PAGE_URLS.DASHBOARD} location={location} title="Dashboard" icon="icon_dashboard" />

              <NavLink to={PAGE_URLS.APPLICATION} location={location} title="Application" icon="icon_application" />

              <NavLink to={PAGE_URLS.FEES} location={location} title="Fees" icon="icon_fees" />

              <NavLink to={PAGE_URLS.DOCUMENTS} location={location} title="Documents" icon="icon_documents" />
            </ul>
          </nav>
        </Styled.Header>
        <Styled.Body className="page-body">
          <Header
            user={user}
            notifications={notifications}
            clearNotification={this.props.removeNotification}
            logout={() => this.props.logout()}
            manageProfile={() => this.props.history.push(PAGE_URLS.APPLICATION)}
          />
          <div onClick={() => this.setState({isMinified: true})}>{React.cloneElement(children, { history: this.props.history })}</div>
        </Styled.Body>
      </Styled.Container>
    );
  }
}

const mapStateToProps = ({ app, account }: {app: IAppReducer, account: IAccountReducer}) => {
  return {
    user: app.user,
    token: app.token,
    notifications: account.notifications
  }
};

const mapDispatchToProps =  {
  me: AccountActions.me,
  logout: AppActions.logout,
  updateUser: AppActions.updateUser,
  addNotification: AccountActions.addNotification,
  removeNotification: AccountActions.removeNotification
};

export default connect(mapStateToProps, mapDispatchToProps)(Page);