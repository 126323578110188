import { PURGE } from 'redux-persist';
import AdminActions, { IAdminReducer } from "./action";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

const adminPersistConfig = {
  key: 'admin',
  storage,
  blacklist: ['isSubmitting', 'error', 'errors', 'success']
};

const initialState: IAdminReducer = {
    error: false,
    errors: [],
    success: false,
    errorMessage: '',
    successMessage: '',
    isSubmitting: false,

    users: [],
    fees: [],
    template: {}
};

const AdminReducer = (state = initialState, action: any) => {
  switch(action.type) {
    case PURGE:
      return initialState

    case AdminActions.ACTIONS.INIT:
      return {
        ...state,
        errors: [],
        error: false,
        success: false,
        errorMessage: '',
        ...action.payload
      };
    
    case AdminActions.ACTIONS.SUCCESS: {
      return {
          ...state,
          errors: [],
          error: false,
          success: true,
          successMessage: action.payload.message,
          ...action.payload
      }
    }

    case AdminActions.ACTIONS.FALIURE: {
      const errors = action.payload.errors? action.payload.errors.body : [];

      return {
          ...state,
          errors,
          error: true,
          success: false,
          isSubmitting: false,
          errorMessage: action.payload.message
      }
    }

    case AdminActions.ACTIONS.DASHBOARD: {
      return {
          ...state,
          isSubmitting: false,
          // fees: action.payload.data
      }
    }

    case AdminActions.ACTIONS.USERS: {
      return {
          ...state,
          isSubmitting: false,
          users: action.payload.data
      }
    }

    case AdminActions.ACTIONS.FEES: {
      return {
          ...state,
          isSubmitting: false,
          fees: action.payload.data
      }
    }

    case AdminActions.ACTIONS.TEMPLATE: {
      return {
          ...state,
          isSubmitting: false,
          template: action.payload.data
      }
    }

    default:
      return state
  }
}

export default persistReducer(adminPersistConfig, AdminReducer);