import { API_URL } from 'src/constants/urls';
import { get, post, put, del, file } from 'src/helpers/api';
import { Home } from '../template/types';
import { IFee } from 'src/pages/account/_redux/action';
import { Actions, IUser, IReducer } from 'src/redux/action';

export interface IAdminReducer extends IReducer {
    users: IUser[],
    fees: IFee[],
    template: {
        home?: Home
    }
    successMessage: string;
}

export default class AdminActions extends Actions {
    public static ACTIONS = {
        INIT: 'ADMIN_INIT',
        FALIURE: 'ERROR_ADMIN',
        SUCCESS: 'SUCCESS_ADMIN',

        DASHBOARD: 'APP_STATS',
        USERS: 'APP_USERS',
        FEES: 'APP_FEES',
        DOCUMENTS: 'APP_DOCUMENTS',
        ADMINS: 'ADMIN_USERS',
        TEMPLATE: 'TEMPLATE',
    };

    public static init(data?: any) {
        return ({
            type: AdminActions.ACTIONS.INIT,
            payload: data,
        });
    }

    public static success(data?: any): any {
        return ({
            type: AdminActions.ACTIONS.SUCCESS,
            payload: data
        });
    }

    public static getAppStats() {
        return async (dispatch: any, getState: any) => {
            try {
                await dispatch(AdminActions.init({isSubmitting: true}));
                
                const { app } = getState();
                const res = await get(API_URL+'fees/', null, app.token);

                dispatch({
                    type: AdminActions.ACTIONS.DASHBOARD,
                    payload:  res
                });
            } catch (err) {
                dispatch(AdminActions.failure(err));
            }
        };
    }

    public static getAppUsers() {
        return async (dispatch: any, getState: any) => {
            try {
                await dispatch(AdminActions.init({isSubmitting: true}));
                
                const { app } = getState();
                const res = await get(API_URL+'users?role=admin', null, app.token);

                dispatch({
                    type: AdminActions.ACTIONS.USERS,
                    payload:  res
                });
            } catch (err) {
                dispatch(AdminActions.failure(err));
            }
        };
    }

    private static changeApplicationStatus(id: string, reject?: boolean) {
        return async (dispatch: any, getState: any) => {
            await dispatch(AdminActions.init({isSubmitting: true}));
                
            const { app, admin } = getState();
            const res = await post(`${API_URL}users/${id}/${reject? "reject": "approve"}-application`, null, app.token);
            
            const users = [ ...admin.users ];
            const userIndex = users.findIndex((e: IUser) => e.id === id);
            users[userIndex] = res;

            dispatch({
                type: AdminActions.ACTIONS.USERS,
                payload:  {data: users}
            });
        };
    }

    public static approveApplication(id: string) {
        return async (dispatch: any) => {
            try {
                const socket = (window as any).socket;
                await dispatch(AdminActions.changeApplicationStatus(id));

                if (socket) {
                    socket.emit("application:approve", id);
                }
                
                dispatch(AdminActions.success({message: "Succesfully approved user Application"}));
            } catch (err) {
                dispatch(AdminActions.failure(err));
            }
        };
    }

    public static rejectApplication(id: string) {
        return async (dispatch: any) => {
            try {
                const socket = (window as any).socket;
                await dispatch(AdminActions.changeApplicationStatus(id, true));

                if (socket) {
                    socket.emit("application:reject", id);
                }
                dispatch(AdminActions.success({message: "Succesfully rejected user Application"}));
            } catch (err) {
                dispatch(AdminActions.failure(err));
            }
        };
    }

    private static changePaymentStatus(id: string, fee: string, reject?: boolean) {
        return async (dispatch: any, getState: any) => {
            await dispatch(AdminActions.init({isSubmitting: true}));
                
            const { app, admin } = getState();
            const res = await post(`${API_URL}users/${id}/${reject? "reject": "approve"}-fee`, { fee }, app.token);
            
            const users = [ ...admin.users ];
            const userIndex = users.findIndex((e: IUser) => e.id === id);
            users[userIndex] = res;

            dispatch({
                type: AdminActions.ACTIONS.USERS,
                payload:  {data: users}
            });
        };
    }

    public static approvePayment(id: string, fee: string) {
        return async (dispatch: any) => {
            try {
                const socket = (window as any).socket;
                await dispatch(AdminActions.changePaymentStatus(id, fee));
                if (socket) {
                    socket.emit("fee:approve", id);
                }
                dispatch(AdminActions.success({message: "Succesfully approved user Fee"}));
            } catch (err) {
                dispatch(AdminActions.failure(err));
            }
        };
    }

    public static rejectPayment(id: string, fee: string) {
        return async (dispatch: any) => {
            try {
                const socket = (window as any).socket;
                await dispatch(AdminActions.changePaymentStatus(id, fee, true));
                if (socket) {
                    socket.emit("fee:reject", id);
                }
                dispatch(AdminActions.success({message: "Succesfully rejected userFee"}));
            } catch (err) {
                dispatch(AdminActions.failure(err));
            }
        };
    }

    public static getAppFees() {
        return async (dispatch: any, getState: any) => {
            try {
                await dispatch(AdminActions.init({isSubmitting: true}));
                
                const { app } = getState();
                const res = await get(API_URL+'fees', null, app.token);

                dispatch({
                    type: AdminActions.ACTIONS.FEES,
                    payload:  res
                });
            } catch (err) {
                dispatch(AdminActions.failure(err));
            }
        };
    }

    public static createFee(data: any) {
        return async (dispatch: any, getState: any) => {
            try {
                await dispatch(AdminActions.init({isSubmitting: true}));
                
                const { app, admin } = getState();
                const res = await post(`${API_URL}fees/`, data, app.token);
                
                const fees: IFee[] = [ ...admin.fees ];
                fees.push(res);

                await dispatch({
                    type: AdminActions.ACTIONS.FEES,
                    payload:  {data: fees}
                });

                dispatch(AdminActions.success({message: "Succesfully created fee"}));
            } catch (err) {
                dispatch(AdminActions.failure(err));
            }
        };
    }

    public static updateFee(id: string, data: IFee) {
        return async (dispatch: any, getState: any) => {
            try {
                await dispatch(AdminActions.init({isSubmitting: true}));
                
                const { app, admin } = getState();
                const res = await put(`${API_URL}fees/${id}`, data, app.token);
                
                const fees = [ ...admin.fees ];
                const feeIndex = fees.findIndex((e: IFee) => e.id === id);
                fees[feeIndex] = res;

                dispatch({
                    type: AdminActions.ACTIONS.FEES,
                    payload:  {data: fees}
                });
            } catch (err) {
                dispatch(AdminActions.failure(err));
            }
        };
    }

    public static deleteFee(id: string) {
        return async (dispatch: any, getState: any) => {
            try {
                await dispatch(AdminActions.init({isSubmitting: true}));
                
                const { app, admin } = getState();
                await del(`${API_URL}fees/${id}`, app.token);
                
                const fees = [ ...admin.fees ];
                const feeIndex = fees.findIndex((e: IFee) => e.id === id);
                
                fees.splice(feeIndex,1);

                dispatch({
                    type: AdminActions.ACTIONS.FEES,
                    payload:  {data: fees} // todo: optimise
                });
            } catch (err) {
                dispatch(AdminActions.failure(err));
            }
        };
    }

    public static createAdmin(data: any) {
        return async (dispatch: any, getState: any) => {
            try {
                await dispatch(AdminActions.init({isSubmitting: true}));
                
                const { app, admin } = getState();
                const res = await post(`${API_URL}users/`, data, app.token);
                
                const users: IUser[] = [ ...admin.users ];
                users.push(res);

                await dispatch({
                    type: AdminActions.ACTIONS.USERS,
                    payload:  {data: users}
                });

                dispatch(AdminActions.success({message: "Succesfully created USER"}));
            } catch (err) {
                dispatch(AdminActions.failure(err));
            }
        };
    }

    public static updateAdmin(id: string, data: IUser) {
        return async (dispatch: any, getState: any) => {
            try {
                await dispatch(AdminActions.init({isSubmitting: true}));
                
                const { app, admin } = getState();
                const res = await put(`${API_URL}users/${id}`, data, app.token);
                
                const users = [ ...admin.users ];
                const userIndex = users.findIndex((e: IUser) => e.id === id && e.role === 'admin');
                users[userIndex] = res;

                dispatch({
                    type: AdminActions.ACTIONS.USERS,
                    payload:  {data: users}
                });
            } catch (err) {
                dispatch(AdminActions.failure(err));
            }
        };
    }

    public static deleteAdmin(id: string) {
        return async (dispatch: any, getState: any) => {
            try {
                await dispatch(AdminActions.init({isSubmitting: true}));
                
                const { app, admin } = getState();
                await del(`${API_URL}users/${id}`, app.token);
                
                const users = [ ...admin.users ];
                const userIndex = users.findIndex((e: IUser) => e.id === id && e.role === 'admin');
                
                users.splice(userIndex, 1);

                dispatch({
                    type: AdminActions.ACTIONS.USERS,
                    payload:  {data: users} // todo: optimise
                });
            } catch (err) {
                dispatch(AdminActions.failure(err));
            }
        };
    }

    public static getTemplate(id: string) {
        return async (dispatch: any, getState: any) => {
            try {
                await dispatch(AdminActions.init({isSubmitting: true}));
                const { app, admin } = getState();

                const res = await get(`${API_URL}template/${id}`, null, app.token);

                console.log(res, admin.template);


                
                // const template: any = [ ...admin.template ];
                // template[id] = res;

                // dispatch({
                //     type: AdminActions.ACTIONS.TEMPLATE,
                //     payload:  {data: template}
                // });
            } catch (err) {
                dispatch(AdminActions.failure(err));
            }
        };
    }

    public static updateTemplate(id: string, data: any) {
        return async (dispatch: any, getState: any) => {
            try {
                await dispatch(AdminActions.init({isSubmitting: true}));
                const { app, admin } = getState();
                const formData = new FormData();
                const values: any = {...data};

                for (const [key, value] of Object.entries(values)) {
                    formData.append(key, value as any);
                }

                const res = await file(`${API_URL}template/${id}`, formData, app.token);
                
                const template: any = [ ...admin.template ];
                template[id] = res;

                dispatch({
                    type: AdminActions.ACTIONS.TEMPLATE,
                    payload:  {data: template}
                });
            } catch (err) {
                dispatch(AdminActions.failure(err));
            }
        };
    }
}