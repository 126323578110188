import React from "react";
import Login from "./login/Loadable";
import { connect } from "react-redux";
import Register from "./register/Loadable";
import Page from "./_shared/components/Page";
import { PAGE_URLS, PAGE_URLS as URLS } from "src/constants/urls";
import LoadingIndicator from "src/components/LoadingIndicator";
import   AuthActions, { IAuthReducer } from "./_redux/action";
import { Switch, Route } from "react-router-dom";

interface Props {
  history?: any;
  router?: any;
  isAdmin: boolean;
  alreadyLoggedIn: boolean | null;
  checkLoginState: () => any;
}

interface State {
  isLoading: boolean;
}

class App extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    this.props.checkLoginState();
  }

  componentDidUpdate(prevProps: Props) {
    const { isAdmin, alreadyLoggedIn } = this.props;
    
    if (this.state.isLoading && (prevProps.alreadyLoggedIn !== alreadyLoggedIn)) {
      this.setState({ isLoading: false });
    }

    if (alreadyLoggedIn) {
        this.props.history.replace(isAdmin? PAGE_URLS.ADMIN : PAGE_URLS.ACCOUNT);
    }
  }

  render() {
    const { isLoading } = this.state;

    if (isLoading)
      return <LoadingIndicator />

    return (
        <Page className="ecss no-max auth">
            <Switch>
              <Route path={URLS.LOGIN} component={ Login } />
              <Route path={URLS.REGISTER} component={ Register } />
            </Switch>
        </Page>
    );
  }
}

const mapStateToProps = ({ auth, app }: {auth: IAuthReducer, app: any}) => {
  return {
    isAdmin: app.user && app.user.role === 'admin',
    alreadyLoggedIn: auth.alreadyLoggedIn,
  }
};

const mapDispatchToProps =  {
  checkLoginState:  AuthActions.checkLoginState
};

export default connect(mapStateToProps, mapDispatchToProps)(App);