import React from 'react';
import * as Styled from "./styles";
import { BASE_URL, PAGE_URLS } from "src/constants/urls";
import { Nav, Navbar, Dropdown } from "react-bootstrap";
import { NavLink as Link } from "react-router-dom";

interface IUrls {
    key: string;
    title: string;
}

type Props = {
    home: string;
    urls?: IUrls[];
    user?: any;
    static?: boolean;
    loginUrl?: IUrls;
    registerUrl?: IUrls;
    className?: string;
    isAuthenticated?: boolean;

    children?: any;
    logout?: () => void;
    manageProfile?: () => void;
};

type State = {
    show: boolean;
    scrollPos: number;
};

class Header extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            show: props.static ? true : false,
            scrollPos: 0
        };
    }

    render() {
        const {
            user,
            urls = [],
            children,
            className = "",
            isAuthenticated = false,
            loginUrl = {
                title: "Login",
                key: PAGE_URLS.LOGIN
            }, registerUrl = {
                title: "Sign up",
                key: PAGE_URLS.REGISTER
            }
        } = this.props;

        return (
            <Styled.HeaderContainer className={`fixed-top position-relative ${className}`}>
                <Styled.StyledNav expand="lg" className={`${isAuthenticated? 'is-auth': ''}`}>
                    <Navbar.Brand href={this.props.home}>
                        <div className="d-flex align-items-center">
                            <img src={BASE_URL+'img/logo.png'} className="logo-img" alt="logo"/>
                        </div>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav"
                                     className="animate__animated animate__fadeIn animate__faster">
                        <Nav className="align-items-center">
                            {urls.map((e,i) => (
                                <Nav.Link key={i} exact={true} as={Link} to={e.key}>{e.title}</Nav.Link>
                            ))}

                            {!isAuthenticated && (
                                <>
                                    {children}
                                    <Nav.Link className="nav-btn app-btn app-btn-outline"
                                            href={registerUrl.key}>
                                        <span>{registerUrl.title}</span>
                                    </Nav.Link>
                                    <Nav.Link className="nav-btn app-btn app-btn-dark" href={loginUrl.key}>
                                        <span>{loginUrl.title}</span>
                                    </Nav.Link>
                                </>
                            )}

                            
                        </Nav>
                    </Navbar.Collapse>
                    {isAuthenticated && (
                        <Styled.AuthContainer>
                            {children}
                            <Dropdown className="user">
                                <Dropdown.Toggle variant="clear">
                                    <img src={BASE_URL+'img/icon_user.svg'} alt="user" />
                                    <span className="px-2 text-capitalize">{user?.firstName}</span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={this.props.manageProfile}>Manage Profile</Dropdown.Item>
                                    <Dropdown.Item onClick={this.props.logout}>Logout</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Styled.AuthContainer>
                    )}
                </Styled.StyledNav>
            </Styled.HeaderContainer>
        );
    }
}

export default Header;
