import Colors from "src/components/Colors";
import styled, { css} from "src/styles/styled-components";
import { BREAKPOINTS, mediaBreakpointUp } from "src/styles/mixins";


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Colors.white};
  padding-top: 120px !important;
  padding-bottom: 50px !important;
  justify-content: center;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('/img/auth/bg.jpg');
  min-height: 100vh;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    background: rgba(0, 0, 0, 0.6);
    /* background: rgba(85, 90, 102, 0.7); */
  }
`;


export const FormContainer = styled.div`
    z-index: 1;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;

    >div {
        min-width: 340px;
        h1 {
            margin-bottom: 35px;
            text-align: center;
            color: ${Colors.white};
            font-style: normal;
            font-weight: bold;
            font-size: 36px;
            line-height: 45px;
            font-family: Merriweather;
        }

        a {
            display: block;
            font-size: 18px;
            margin-top: 24px;
            line-height: 25px;
            font-weight: bold;
            text-align: center;
            color: ${Colors.white};
        }

        form {
            .input-container {
                padding: 12px 10px;
                position: relative;
    
                &.with-icon {
                    i {
                        right: 20px;
                        width: 24px;
                        height: 24px;
                        display: block;
                        cursor: pointer;
                        position: absolute;
                        top: calc(50% - 8px);

                        &.is-invalid {
                            color: #dc3545;
                        }
                    }

                    .form-control {
                        padding-right: calc(24px + 20px);
                        &.is-invalid {
                            background-image: none;
                        }
                    }
                }

                &.date-container {
                    .SingleDatePicker {
                        .SingleDatePickerInput {
                            .DateInput{
                            }
                        }
                    }
                }

                &.file-container {
                    padding-top: 0;
                    padding-bottom: 0;

                    input {
                        opacity: 0;
                    }

                    div {
                        top: 0;
                        z-index: -1;
                        height: 53px;
                        padding: 8px 22px;
                        position: absolute;
                        background: #FFFFFF;
                        border-radius: .25rem;
                        border: 1px solid #555A66;

                        display: flex;
                        align-items: center;

                        span {
                            font-size: 18px;
                            line-height: 25px;
                            color: #000000;
                            opacity: 0.5;
                            display: inline-block;
                            margin-right: 25px;
                        }

                        i { 
                            font-size: 38px;
                        }
                    }
                }
    
                .form-control {
                    height: 53px;
                    font-family: Nunito;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 18px;
                    line-height: 25px;
                    background-clip: unset;
    
                    ::placeholder {
                        opacity: 0.5;
                    }
                }

                .form-select {
                    height: 53px;
                    font-family: Nunito;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 18px;
                    line-height: 25px;
                    color: rgba(0,0,0, .5);

                    &:valid {
                        color: #000000;
                    }
                }
    
                .form-check {
                    .form-check-input {
                        width: 16px;
                        height: 16px;

                        &.is-invalid {
                            border-color: #dc3545;
                        }
                    }
    
                    .form-check-label {
                        font-size: 16px;
                        line-height: 22px;
                        color: #FFFFFF;
                        margin-top: .2rem;
                        margin-left: 8px;
                    }
                }
            }

            .btn-container {
                text-align: center;
                .app-btn {
                    font-weight: bold;
                    font-size: 24px;
                    line-height: 33px;
                }
            }
        }
    }

    ${mediaBreakpointUp(BREAKPOINTS.md, css`
        >div {
            min-width: 500px;
        }
    `)};
`;