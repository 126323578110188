import { PURGE } from 'redux-persist';
import AppActions, { IAppReducer } from './action';
import { combineReducers } from 'redux';
import AuthStateReducer from 'src/pages/auth/_redux/reducer';
import AdminStateReducer from 'src/pages/admin/_redux/reducer';
import AccountStateReducer from 'src/pages/account/_redux/reducer';

const initialState: IAppReducer = {
    error: false,
    success: false,
    errorMessage: '',
    isSubmitting: false,

    user: null,
    token: null,
};

const AppStateReducer = (state = initialState, action: any) => {
  switch(action.type) {
    case PURGE:
      return initialState

    case AppActions.ACTIONS.LOGIN: {
      return {
          ...state,
          error: false,
          success: false,
          user: action.payload.user,
          token: action.payload.token,
      }
    }

    case AppActions.ACTIONS.UPDATE_USER: {
      return {
          ...state,
          error: false,
          success: false,
          user: action.payload,
      }
    }

    default:
      return state
  }
}

export default combineReducers({
  app: AppStateReducer,
  auth: AuthStateReducer,
  admin: AdminStateReducer,
  account: AccountStateReducer
});