import React from 'react';
import Life from "./life/Loadable";
import Home from "./landing/Loadable";
import About from "./about/Loadable";
import Admissions from "./admissions/Loadable";
import Contact from "./contact/Loadable";
import Static from "./static/Loadable";
import { SCHOOL_URLS } from 'src/constants/urls';
import { Switch, Route } from "react-router-dom";

function App() {
  return (
      <div className="ecss">
          <Switch>
            <Route path={SCHOOL_URLS.ABOUT}>
              <About />
            </Route>

            <Route exact={true} path={SCHOOL_URLS.ADMISSION}>
              <Admissions />
            </Route>

            <Route exact={true} path={SCHOOL_URLS.LIFE}>
              <Life />
            </Route>

            <Route exact={true} path={SCHOOL_URLS.CONTACT}>
              <Contact />
            </Route>

            <Route exact={true} path={SCHOOL_URLS.HOME}>
              <Home />
            </Route>

            <Route exact={true} path={SCHOOL_URLS.ABOUT}>
              <About />
            </Route>

            <Route exact={true} path={SCHOOL_URLS.PRIVACY_POLICY}>
              <Static title="Privacy policy" component="privacy" />
            </Route>

            <Route exact={true} path={SCHOOL_URLS.TERMS_AND_CONDITIONS}>
              <Static title="Terms and conditions" component="terms" />
            </Route>

            <Route path="*">
              <Home />
            </Route>
          </Switch>
      </div>
  );
}

export default App;
