import { PAGE_URLS as URLS } from "src/constants/urls";

const ACCOUNT_URL = URLS.ACCOUNT;

export const PAGE_URLS = {
  DASHBOARD: `${ACCOUNT_URL}`,
  APPLICATION: `${ACCOUNT_URL}application/`,
  FEES: `${ACCOUNT_URL}fees/`,
  DOCUMENTS: `${ACCOUNT_URL}documents/`,
  LOGOUT: `${ACCOUNT_URL}logout/`,
};
