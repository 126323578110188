


export const BASE_URL = process.env.REACT_APP_BASE_URL || '/';

export const SERVER_URL = process.env.REACT_APP_SERVER_URL || 'https://elevatingarms.com/';
export const API_URL = process.env.REACT_APP_API_URL || SERVER_URL+'api/';

export const PAGE_URLS = {
  HOME: `${BASE_URL}`,
  SCHOOL: `${BASE_URL}ecss/`,
  SKILL: `${BASE_URL}skill/`,
  EVENTS: `${BASE_URL}events/`,
  LOGIN: `${BASE_URL}login/`,
  REGISTER: `${BASE_URL}register/`,
  ACCOUNT: `${BASE_URL}account/`,
  ADMIN: `${BASE_URL}admin/`,
};

export const SCHOOL_URL: string = PAGE_URLS.SCHOOL;
export const SCHOOL_URLS = {
  HOME: `${SCHOOL_URL}`,
  ABOUT: `${SCHOOL_URL}about-us/`,
  ADMISSION: `${SCHOOL_URL}admissions/`,
  LIFE: `${SCHOOL_URL}ecss-life/`,
  CONTACT: `${SCHOOL_URL}contact-us/`,
  PRIVACY_POLICY: `${SCHOOL_URL}privacy-policy/`,
  TERMS_AND_CONDITIONS: `${SCHOOL_URL}terms-and-conditions/`,
};
