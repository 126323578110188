import React from 'react';
import io from "socket.io-client";
import { Provider } from 'react-redux';
import GlobalStyle from "src/styles/global";
import LoadingIndicator from "src/components/LoadingIndicator";
import { PAGE_URLS, SERVER_URL } from "./constants/urls";
import { Home, School, Auth, Account, Admin } from "./pages";
import PrivateRoute from "src/components/PrivateRoute";
import { PersistGate } from "redux-persist/integration/react";
import { Theme, ThemeProvider } from "src/styles/styled-components";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";


import Static from "./pages/school/static/Loadable";

import { store, persistor } from "./redux/store";

function App() {

  const [loading, setLoading] = React.useState(true);

  const loadSock = async () => {
    if (!(window as any).socket) {
      (window as any).socket = await io(SERVER_URL);
      console.log('connected');
      setLoading(false);
      return;
    }
  }

  loadSock();

  if (loading){
    return <LoadingIndicator />;
  }

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={Theme}>
          <div>
            <Router>
              <Switch>

                <Route path={PAGE_URLS.SCHOOL} component={ School } />

                <Route path={PAGE_URLS.LOGIN} component={ Auth } />

                <Route path={PAGE_URLS.REGISTER} component={ Auth } />

                <Route exact={true} path={PAGE_URLS.SKILL}>
                  <Static title="Skill Acquisition Center" component="skill" />
                </Route>

                <Route exact={true} path={PAGE_URLS.EVENTS}>
                  <Static title="Event Center" component="event" />
                </Route>

                <PrivateRoute path={PAGE_URLS.ACCOUNT}>
                  <Account />    
                </PrivateRoute>

                <PrivateRoute admin path={PAGE_URLS.ADMIN}>
                  <Admin />    
                </PrivateRoute>

                <Route path="*">
                  <Home />
                </Route>
              </Switch>
            </Router>
          </div>
          <GlobalStyle />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
