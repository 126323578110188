import { PURGE } from 'redux-persist';
import AccountActions, { IAccountReducer } from "./action";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

const accountPersistConfig = {
  key: 'account',
  storage,
  blacklist: ['isSubmitting', 'error', 'errors', 'success']
};

const initialState: IAccountReducer = {
    error: false,
    errors: [],
    success: false,
    errorMessage: '',
    successMessage: '',
    isSubmitting: false,

    fees: [],
    notifications: [],
};

const AccountReducer = (state = initialState, action: any) => {
  switch(action.type) {
    case PURGE:
      return initialState

    case AccountActions.ACTIONS.INIT:
      return {
        ...state,
        errors: [],
        error: false,
        success: false,
        ...action.payload
      };

    
    
    case AccountActions.ACTIONS.SUCCESS: {
        return {
            ...state,
            errors: [],
            error: false,
            success: true,
            isSubmitting: false,
            successMessage: action.payload.message,
            ...action.payload
        }
      }

    case AccountActions.ACTIONS.FALIURE: {
      const errors = action.payload.errors? action.payload.errors.body : [];

      return {
          ...state,
          errors,
          error: true,
          success: false,
          isSubmitting: false,
          errorMessage: action.payload.message
      }
    }

    case AccountActions.ACTIONS.FEES: {
      return {
          ...state,
          fees: action.payload.data
      }
    }

    case AccountActions.ACTIONS.NOTIFICATION_ADD: {
      const notifications = state.notifications || [];
      return {
          ...state,
          notifications: [action.payload, ...notifications]
      }
    }

    case AccountActions.ACTIONS.NOTIFICATION_REMOVE: {
      return {
          ...state,
          notifications: action.payload
      }
    }

    default:
      return state
  }
}

export default persistReducer(accountPersistConfig, AccountReducer);