import styled from "src/styles/styled-components";

const Wrapper = styled.div`
  margin: 2em auto;
  width: 40px;
  height: 40px;
  position: relative;
`;

export default Wrapper;
