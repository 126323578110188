import { API_URL } from "src/constants/urls";
import { get, del, post, file } from "src/helpers/api";
import AppActions, { Actions, IUserForm, IReducer } from "src/redux/action";

export interface IFee {
    id: string;
    name: string;
    amount: number;
    createdAt: Date;
}

export interface IAccountReducer extends IReducer {
    fees: IFee[];
    notifications: {
        message: string;
        createdAt: string;
    }[];
}

export default class AccountActions extends Actions {
    public static ACTIONS = {
        INIT: 'ACCOUNT_INIT',
        FALIURE: 'ERROR_ACCOUNT',
        SUCCESS: 'SUCCESS_ACCOUNT',

        FEES: 'FEES',
        NOTIFICATION_ADD: 'ADD_NOTIFICATION',
        NOTIFICATION_REMOVE: 'NOTIFICATION_REMOVE'
    };

    public static success(data?: any): any {
        return ({
            type: AccountActions.ACTIONS.SUCCESS,
            payload: data
        });
    }

    public static addNotification(data: any) {
        if (!data.createdAt)
            data.createdAt = (new Date()).toString();
        return ({
            type: AccountActions.ACTIONS.NOTIFICATION_ADD,
            payload:  data
        });
    }

    public static removeNotification(index: number) {
        return async (dispatch: any, getState: any) => {
            const { account } = getState();

            const notifications = [...account.notifications];
            notifications.splice(index, 1);
            dispatch({
                type: AccountActions.ACTIONS.NOTIFICATION_REMOVE,
                payload:  notifications
            });
        };
    }

    public static updateUser(data: IUserForm) {
        return async (dispatch: any, getState: any) => {
            try {
                await dispatch(AccountActions.init({isSubmitting: true}));
                const { app } = getState();
                const formData = new FormData();
                const values: any = {...data};

                for (const [key, value] of Object.entries(values)) {
                    formData.append(key, value as any);
                }

                const res = await file(API_URL+'me/', formData, app.token);
                await dispatch(AppActions.updateUser(res));
                dispatch(AccountActions.success({message: "Succesfully updated Application"}));

                const socket = (window as any).socket;
                if (socket) {
                    socket.emit("admin:notify", `${app.user.firstName} ${app.user.lastName} updeted application`);
                }

            } catch (err) {
                dispatch(AccountActions.failure(err));
            }
        };
    }

    public static me() {
        return async (dispatch: any, getState: any) => {
            try {
                await dispatch(AccountActions.init({isSubmitting: true}));
                
                const { app } = getState();
                const res = await get(API_URL+'me/', null, app.token);

                dispatch(AppActions.updateUser(res));
            } catch (err) {
                dispatch(AccountActions.failure(err));
            }
        };
    }

    public static getFees() {
        return async (dispatch: any, getState: any) => {
            try {
                await dispatch(AccountActions.init({isSubmitting: true}));
                
                const { app } = getState();
                const res = await get(API_URL+'fees/', null, app.token);

                dispatch({
                    type: AccountActions.ACTIONS.FEES,
                    payload:  res
                });
            } catch (err) {
                dispatch(AccountActions.failure(err));
            }
        };
    }

    public static makePayment(data: any) {
        return async (dispatch: any, getState: any) => {
            try {
                await dispatch(AccountActions.init({isSubmitting: true}));
                
                const { app } = getState();
                const res = await post(API_URL+'me/pay', data, app.token);

                await dispatch(AppActions.updateUser(res));
                dispatch(AccountActions.addNotification({
                    message: "Payment successful"
                }));
                dispatch(AccountActions.success({message: "Payment successful"}));

                const socket = (window as any).socket;
                if (socket) {
                    socket.emit("admin:notify", `${app.user.firstName} ${app.user.lastName} made a payment`);
                }
            } catch (err) {
                dispatch(AccountActions.failure(err));
            }
        };
    }

    public static deletePayment(id: string) {
        return async (dispatch: any, getState: any) => {
            try {
                await dispatch(AccountActions.init({isSubmitting: true}));
                
                const { app } = getState();
                const res = await del(API_URL+'me/pay/'+id, app.token);

                await dispatch(AppActions.updateUser(res));
                dispatch(AccountActions.addNotification({
                    message: "Succesfully deleted Fee"
                }))
            } catch (err) {
                dispatch(AccountActions.failure(err));
            }
        };
    }
}