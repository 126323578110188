import * as styledComponents from "styled-components";
import Colors from "src/components/Colors";

export const Theme = {
  primary: Colors.primary,
  secondary: Colors.coral,
  warn: Colors.sunsetOrange,
};
const {
  default: styled,
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider
} = styledComponents as styledComponents.ThemedStyledComponentsModule<
  any
>;

export { css, createGlobalStyle, keyframes, ThemeProvider }

export default styled;
