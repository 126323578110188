import AuthActions, { IAuthReducer } from "./action";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

const authPersistConfig = {
  key: 'auth',
  storage,
  blacklist: ['isSubmitting', 'alreadyLoggedIn', 'error', 'errors', 'errorMessage','success']
};

const initialState: IAuthReducer = {
    error: false,
    errors: [],
    success: false,
    errorMessage: '',
    isSubmitting: false,
    alreadyLoggedIn: null,

    // register
    user: {
      step: 0,
      firstName: '',
      lastName: '',
      nationality: '',
      birthDay: '',
      gender: '',
      sponsor: '',
      sponsorRelationship: '',
      phone: '',
      email: '',
      address: '',
      state: '',
      lga: '',
      city: '',
      password: '',
      sponsorPhone: '',
      schoolName: '',
      schoolFrom: '',
      schoolTo: '',
      qualification: '',
    }
};

const AuthReducer = (state = initialState, action: any) => {
  switch(action.type) {
    case AuthActions.ACTIONS.INIT:
      return {
        ...state,
        errors: [],
        error: false,
        success: false,
        ...action.payload
      };

    case AuthActions.ACTIONS.FALIURE: {
      const errors = action.payload.errors? action.payload.errors.body : [];

      return {
          ...state,
          errors,
          error: true,
          success: false,
          isSubmitting: false,
          errorMessage: action.payload.message
      }
    }

    case AuthActions.ACTIONS.SET_USER_DETAILS: {
      return {
          ...state,
          isSubmitting: false,
          user: {
            ...state.user,
            ...action.payload
          }
      }
    }

    case AuthActions.ACTIONS.LOGIN: {
      return {
          ...state,
          error: false,
          success: true,
          isSubmitting: false,
          alreadyLoggedIn: true,
      }
    }

    case AuthActions.ACTIONS.ALREADY_LOGGED_IN: {
      return {
          ...state,
          alreadyLoggedIn: true,
      }
    }

    default:
      return state
  }
}

export default persistReducer(authPersistConfig, AuthReducer);