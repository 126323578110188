import React from 'react';
import * as Styled from "./styles";
import { BASE_URL } from "src/constants/urls";
import { Dropdown } from "react-bootstrap";
import moment from 'moment';

type Props = {
    user: any;
    notifications: any[];
    clearNotification?: (index: number) => void;
};

const Header = ({user, notifications, clearNotification = (i: any) => {}}: Props) => {
    return (
        <Styled.Notification>
            <Dropdown className="notification">
                <Dropdown.Toggle variant="clear">
                    <img src={BASE_URL+'img/icon_bell.svg'} alt="notifications" />
                    {notifications.length > 0  && (
                        <span className="position-absolute translate-middle badge rounded-pill bg-danger">
                            {notifications.length < 100? notifications.length: '99+'}
                        </span>
                    )}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {notifications.length < 1 &&(<div className="dropdown-menu-header">Nothing here yet</div>)}
                    {notifications.map((e, i) => (
                        <div key={i} className="dropdown-item" onClick={() => clearNotification(i)}>
                            <h6 className="dropdown-item-heading">{e.message}</h6>
                            <small className="text-muted">{moment(e.createdAt).fromNow()}</small>
                        </div>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </Styled.Notification>
    );
};

export default Header;
