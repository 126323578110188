import React from "react";
import Page from "./_shared/components/Page";
import Fees from "./fees/Loadable";
import Dashboard from "./dashboard/Loadable";
import Documents from "./documents/Loadable";
import Application from "./application/Loadable";
import { PAGE_URLS } from "./_shared/constants/urls";
import { Switch, Route } from "react-router-dom";

interface Props {
  history?: any;
  router?: any;
  location?: any;
}

class App extends React.Component<Props, any> {
  render() {
    return (
        <Page className="ecss account" history={this.props.history} location={this.props.location}>
          
            <Switch>
              <Route path={PAGE_URLS.APPLICATION} component={Application} />
              <Route path={PAGE_URLS.DOCUMENTS} component={Documents} />
              <Route path={PAGE_URLS.FEES} component={Fees} />


              <Route exact path="*" component={Dashboard} />
            </Switch>
        </Page>
    );
  }
}

export default App;