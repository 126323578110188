import { PURGE } from 'redux-persist';

interface IStatus {
    statusChangedBy?: string;
    statusChangedDate?: Date;
    status?: "approved" | "rejected" | "pending";
}

export interface IUserForm {
    firstName: string;
    lastName: string;
    nationality: string;
    birthDay: string;
    gender: string;
    phone: string;
    email: string;
    address: string;
    state: string;
    lga: string;
    city: string;
    sponsor: string;
    sponsorPhone: string;
    sponsorRelationship: string;
    schoolName: string;
    schoolFrom: string;
    schoolTo: string;
    qualification?: string;
    photo?: File;
    certificate?: File;
    password?: string;
}
  
export interface IUserApplication extends IStatus {
    lga: string;
    city: string;
    state: string;
    address: string;
    nationality: string;

    sponsor: string;
    sponsorPhone: string;
    sponsorRelationship: string;

    schoolName: string;
    schoolFrom: string;
    schoolTo: string;
    certificate: string;
    qualification: string;
    number?: string;
}

export interface IUserFee extends IStatus {
    fee: string;
    amount: number;
    receipt?: string;
    paymentDate: string;
    paymentDetails?: any;
}

export interface IUser {
    id: string;
    firstName: string;
    lastName: string;
    gender: string;
    phone: string;
    email: string;
    photo: string;
    birthDay: string;
    role: "user" | "admin";
    application?: IUserApplication;
    fees?: IUserFee[],
    createdAt: string;
}

export interface IReducer {
    error: boolean;
    success: boolean;

    errors?: any[];
    errorMessage?: string;
    successMessage?: string;

    isSubmitting: boolean;
}

export class Actions {
    public static ACTIONS: {[key: string]: string;} = {
        INIT: 'INIT',
        FALIURE: 'ERROR',
        SUCCESS: 'SUCCESS',
        LOGOUT: 'LOGOUT_APP'
    };
  
    public static init(data?: any) {
        return ({
            type: this.ACTIONS.INIT,
            payload: data,
        });
    }

    public static success(data?: any): any {
        return ({
            type: this.ACTIONS.SUCCESS,
            payload: data
        });
    }

    public static failure(err: any ): any {
        return ({
            type: this.ACTIONS.FALIURE,
            payload: err
        });
    }

    public static logout() {
        return async (dispatch: any) => {
            dispatch({
                type: PURGE,
                result: () => null   
            });
        };
    }
}

export interface IAppReducer extends IReducer {
    user: any;
    token: string | null;
}

export default class AppActions extends Actions{
    public static ACTIONS  = {
        ...Actions.ACTIONS,
        LOGIN: 'LOGIN_APP',
        UPDATE_USER: 'UPDATE_USER',
    };
  
    public static login(token: string, user: IUser)  {
        return ({
            type: AppActions.ACTIONS.LOGIN,
            payload: {
                token,
                user
            },
        })
    }

    public static updateUser(user: IUser)  {
        return ({
            type: AppActions.ACTIONS.UPDATE_USER,
            payload: user,
        })
    }
}
