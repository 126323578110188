import React from 'react';
import BaseHeader from "src/components/Header";
import { PAGE_URLS, SCHOOL_URLS } from "src/constants/urls";

const Header = () => {
    return (
        <BaseHeader
            home={PAGE_URLS.HOME}
            urls={[
                {
                    key: SCHOOL_URLS.HOME,
                    title: 'Home'
                },
                {
                    key: SCHOOL_URLS.ABOUT,
                    title: 'About Us'
                },
                {
                    key: SCHOOL_URLS.ADMISSION,
                    title: 'Admissions'
                },
                {
                    key: SCHOOL_URLS.LIFE,
                    title: 'Life at ECSS'
                },
                {
                    key: SCHOOL_URLS.CONTACT,
                    title: 'Contact us'
                }
            ]}
        />
    );
};

export default Header;
