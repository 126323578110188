import Colors from "src/components/Colors";
import styled, { css} from "src/styles/styled-components";
import { BREAKPOINTS, mediaBreakpointUp } from "src/styles/mixins";


export const Container = styled.div`
  position: relative;

  .page-alert {
        z-index: 9999;
        position: absolute;
        bottom: 50px;
        left: 50px;

        .btn-close {
            font-size: 12px;
        }
    }
`;


export const Header = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 80%;
    z-index: 999;
    height: 100vh;
    padding-top: 70px;
    transition: .2s ease-in all;
    background-color: ${Colors.primary};

    nav {
        display: flex;
        flex-direction: column;
        min-height: 100%;

        .toggle-mob-menu {
            top: 18px;
            border: none;
            width: 32px;
            right: -140px;
            z-index: 999;
            padding: 4px 8px;
            position: absolute;
            box-shadow: none !important;
            color: ${Colors.black};
            background: transparent;
            display: none;
        }

        .admin-menu {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            padding: 0;
            margin-top: 95px;

            li {
                margin-bottom: 0px;

                > * {
                    width: 100%;
                    padding: 20px 10px;
                }

                a, button {
                    opacity: .5;
                    display: flex;
                    align-items: center;
                    text-decoration: none;

                    img {
                        width: 35px;
                        margin-right: 15px;
                    }

                    span {
                        font-weight: bold;
                        font-size: 16px;
                        color: ${Colors.dark};
                    }

                    &.active {
                        opacity: 1;
                        border-left: 3px solid ${Colors.dark};
                    }
                }
            }
        }
    }

    .is-minified & {
        left: -80%;
        nav {
            .toggle-mob-menu {
                display: block;
            }
        }
    }

    ${mediaBreakpointUp(BREAKPOINTS.md, css`
        height: 100%;
        width: 360px;
        padding-top: 120px;

        nav {
            .toggle-mob-menu {
                top: auto;
                right: auto;
                left: 20px;
                bottom: 25px;
                display: block;
            }

            .admin-menu {
                margin-top: 95px;

                li {
                    > * {
                        padding: 20px 15px;
                    }

                    a, button {
                        img {
                            margin-right: 25px;
                        }

                        span {
                            font-size: 24px;
                            line-height: 33px;
                        }
                    }
                }
            }
        }

        .is-minified & {
            left: 0;
            width: 80px;
            nav {
                .admin-menu {
                    li {
                        a, button {
                            span {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    `)};

    ${mediaBreakpointUp(BREAKPOINTS.xl, css`
        .is-minified & {
            left: 0;
            width: 360px;

            nav {
                .toggle-mob-menu {
                    display: none;
                }
                .admin-menu {
                    li {
                        a, button {
                            span {
                                display: inline;
                            }
                        }
                    }
                }
            }
        }
    `)};
`;

export const Body = styled.div`
    position: relative;
    min-height: 100vh;
    padding: 20px;
    padding-top: calc(70px + 50px);
    transition: .2s ease-in all;
    background-color: ${Colors.lightGray};

    .account-header {
        z-index: 9;

        .navbar-toggler {
            display: none;
        }
    }

    ${mediaBreakpointUp(BREAKPOINTS.md, css`
        padding: 50px;
        padding-top: calc(120px + 60px);
        margin-left: 359px;

        .account-header {
            z-index: 999;
        }

        .is-minified & {
            margin-left: 80px;
        }
    `)};

    ${mediaBreakpointUp(BREAKPOINTS.xl, css`
        .is-minified & {
            margin-left: 359px;
        }
    `)};
`;