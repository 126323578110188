import React from "react";
import { connect } from "react-redux";
import Page from "./_shared/components/Page";
import Documents from "./dashboard/Loadable";
import Dashboard from "./dashboard/Loadable";
import Admins from "./admins/Loadable";
import Users from "./users/Loadable";
import Fees from "./fees/Loadable";
import Template from "./template/Loadable";
import { PAGE_URLS } from "./_shared/constants/urls";
import { Switch, Route } from "react-router-dom";
import AdminActions from "./_redux/action";

interface Props {
  history?: any;
  router?: any;
  location?: any;


  getAppUsers: () => void;
  getAppFees: () => void;
}

class App extends React.Component<Props, any> {
  componentDidMount() {
    this.props.getAppUsers();
    this.props.getAppFees();
  }

  render() {
    return (
        <Page className="ecss admin" history={this.props.history} location={this.props.location}>
            <Switch>
              <Route path={PAGE_URLS.DOCUMENTS} component={Documents} />
              <Route path={PAGE_URLS.USERS} component={Users} />
              <Route path={PAGE_URLS.FEES} component={Fees} />
              <Route path={PAGE_URLS.ADMINS} component={Admins} />
              <Route path={PAGE_URLS.PAGES} component={Template} />

              <Route exact path="*" component={Dashboard} />
            </Switch>
        </Page>
    );
  }
}

const mapStateToProps = ({ app }: any) => {
  return {
    user: app.user
  }
};

const mapDispatchToProps =  {
  getAppUsers: AdminActions.getAppUsers,
  getAppFees: AdminActions.getAppFees,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);