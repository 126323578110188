import React from "react";
import { connect } from "react-redux";
import * as Styled from "./styles";
import Header from "../Header";
import { BASE_URL } from "src/constants/urls";
import { PAGE_URLS } from "../../constants/urls";
import { NavLink as Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import AppActions, { IAppReducer } from "src/redux/action";

import 'react-toastify/dist/ReactToastify.css';

interface Props {
  user: any;
  children: any;
  className?: string;
  success: string;
  successMessage: string;

  history?: any;
  location?: any;
  logout: () => any;
}

interface State {
  isMinified: boolean;
}

const NavLink = ({to, icon, title, exact = false}: any) => {
  return (
    <li>
      <Link exact={exact} to={to}>
        <img src={`${BASE_URL}img/${icon}.svg`} alt={title}/>
        <span>{title}</span>
      </Link>
    </li>
  )
};

class Page extends React.Component<Props, State> {
  public socket: any | undefined;

  public constructor(props: Props) {
    super(props);

    this.state = {
      isMinified: true,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    this.socket = (window as any).socket;
    if (this.socket) {
      this.socket.on('admin', (data: any) => {
        console.log(data)
        toast.info(data.message)
      });
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.location !== this.props.location) {
      this.setState({isMinified: true});
    }

    if (this.props.success && prevProps.success !== this.props.success) {
      toast.success(this.props.successMessage);
    }
  }

  render() {
    const { isMinified } = this.state;
    const { user, className = '', children } = this.props;
    
    return (
      <Styled.Container className={`${className} ${isMinified? 'is-minified': ''}`}>
        <Styled.Header className="page-header">
          <nav>
            <button
              className="toggle-mob-menu btn btn-light"
              aria-expanded="false"
              aria-label="open menu"
              onClick={() => this.setState({isMinified: !isMinified})}
            >
              <i className="fa fa-bars" />
            </button>

            <ul className="admin-menu">
              <NavLink exact to={PAGE_URLS.DASHBOARD} title="Dashboard" icon="icon_dashboard" />

              <NavLink to={PAGE_URLS.USERS} title="Users" icon="icon_user" />

              <NavLink to={PAGE_URLS.FEES} title="Fees" icon="icon_fees_active" />

              <NavLink to={PAGE_URLS.DOCUMENTS} title="Documents" icon="icon_documents_active" />

              <NavLink to={PAGE_URLS.ADMINS} title="Admins" icon="icon_user" />

              <NavLink to={PAGE_URLS.PAGES} title="Frontend Pages" icon="icon_documents_active" />
            </ul>
          </nav>
        </Styled.Header>
        <Styled.Body className="page-body">
          <Header user={user} logout={() => this.props.logout()} manageProfile={() => {console.log('manageProfile')}}/>
          <div onClick={() => this.setState({isMinified: true})}>
            {React.cloneElement(children, { history: this.props.history })}
          </div>
        </Styled.Body>
        <ToastContainer
          position="top-right"
          autoClose={3000}
        />
      </Styled.Container>
    );
  }
}

const mapStateToProps = ({ app, admin }: {app: IAppReducer, admin: any}) => {
  return {
    user: app.user,
    success: admin.success,
    successMessage: admin.successMessage,
  }
};

const mapDispatchToProps =  {
  logout: AppActions.logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(Page);