
import { createGlobalStyle, css } from "styled-components";

import './style.scss';
import Colors from "src/components/Colors";
import { BREAKPOINTS, mediaBreakpointUp } from "./mixins";

const globalStyles = css`
    .tox {
        display: none;
    }
    
  body {
    color: #41413F;
    max-width: 100%;
    overflow-x: hidden;
    font-family: 'Nunito', sans-serif;

    h1 {
        color: #1B202B;
    }

    .ecss {
        margin-left: auto;
        margin-right: auto;
        position: relative;
        max-width: ${BREAKPOINTS.xxl}px;

        .text-muted {
            color: #999999 !important;
        }

        ${mediaBreakpointUp(BREAKPOINTS.xxl+1, css`
            box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.15);
        `)};

        &.no-max {
            max-width: none;
            box-shadow: none;
        }
    }
    
    .clickable {
        cursor: pointer;
    }

    .required {
      position: relative;
      font-size: 18px;

      &:after {
        content: "*";
        color: #DD0000;
        position: absolute;
        top: 0;
        right: -9px;
        font-size: 15px;
        line-height: 20px;
        font-weight: 400;
      }
    }

    .required-black {
        position: relative;
        top: -5px;
        font-size: 10px;
        line-height: 24px;
    }

    .text-danger {
        color: #DD0000 !important;
    }

    .btn-clear {
        &:focus{
            box-shadow: none;
        }
    }

    .nav-link {
        cursor: pointer !important;
        padding: 0;
    }

    .modal-backdrop.show {
        opacity: .4;
    }

    .modal-video-container {
        overflow: hidden;
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        >iframe {
            border: 0;
            width: 100%;
        }

    }

    .video-container {
        overflow: hidden;
        position: relative;
        border-radius: 15px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        >iframe {
            border: 0;
            width: 100%;
        }

        >img {
            width: 76px;
            height: 76px;
            position: absolute;
            top: calc(50% - 38px);
            left: calc(50% - 38px);
            z-index: 2;
        }

        &:after {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            background: rgba(0, 0, 0, 0.4);
        }
    }

    .app-btn {
        padding: 13px 38px;
        font-weight: 800;
        font-size: 15px;
        line-height: 20px;
        text-align: center;
        border: none;
        border-radius: 6px;
        text-decoration: none;

        i {
            padding-left: 10px;
        }

        &:hover {
            background-color: #B41414;
        }

        &.app-btn-default {
            background: ${Colors.primary};
            color: ${Colors.black};

            &:hover {
                background: ${Colors.white};
            }
        }

        &.app-btn-secondary {
          background: ${Colors.dark};
          color: ${Colors.primary};

          &:hover {
              background: ${Colors.dark};
          }
        }

        &.app-btn-outline {
            border: 1px solid ${Colors.dark};
            background: transparent;
            color: ${Colors.dark};

            &:hover {
                background: ${Colors.dark};
                color: ${Colors.white};
            }
        }

        &.app-btn-light {
            border: 2px solid rgba(180, 20, 20, 0.1);
            color: #B41414;
            background: transparent;

            &:hover {
              background: #B41414;
              color: #ffffff;
            }
        }

        &.app-btn-dark {
          background: ${Colors.dark};
          color: ${Colors.white};

          &:hover {
            color: ${Colors.dark};
            background: transparent;
            border: 1px solid ${Colors.dark};
          }
      }
    }
  }
`;

export default createGlobalStyle`
  ${globalStyles}
`;
