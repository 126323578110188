import React from "react";
import Notification from "./Notification";
import BaseHeader from "src/components/Header";
import { PAGE_URLS, SCHOOL_URLS } from "src/constants/urls";

type Props = {
    user: any;
    notifications: any[];
    clearNotification: (index: number) => void;
    logout: () => void;
    manageProfile: () => void;
};

const Header = ({user, notifications, clearNotification, logout, manageProfile}: Props) => {
    return (
        <BaseHeader
            className="account-header"
            user={user}
            home={PAGE_URLS.HOME}
            isAuthenticated
            urls={[
                {
                    key: PAGE_URLS.HOME,
                    title: 'Home'
                },
                {
                    key: SCHOOL_URLS.ABOUT,
                    title: 'About Us'
                },
                {
                    key: SCHOOL_URLS.ADMISSION,
                    title: 'Admissions'
                },
                {
                    key: SCHOOL_URLS.LIFE,
                    title: 'Life at ECSS'
                },
                {
                    key: SCHOOL_URLS.CONTACT,
                    title: 'Contact us'
                }
            ]}
            logout={logout}
            manageProfile={manageProfile}
        >
            <div>
                <Notification user={user} notifications={notifications} clearNotification={clearNotification} />
            </div>
        </BaseHeader>
    );
};

export default Header;
