import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

function PrivateRoute({ children, admin = false, ...rest }: any) {
    const { token, user } = useSelector((state: any) => state.app);
    let isAuthenticated: boolean = token? true : false;


    if (isAuthenticated && admin) {
      isAuthenticated = user.role === 'admin';
    }

    return (
      <Route {...rest} render={(props) => {
        return isAuthenticated
          ? React.cloneElement(children, { ...props })
          : <Redirect to={{
                pathname: '/login',
                state: { from: props.location }
                }}
            />
      }} />
    )
}

export default PrivateRoute;