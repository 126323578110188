export interface IAPIError {
    message: string
    errors? : any
    path? : any
    status?: number
  }

/**
 * @extends Error
 */
class ExtendableError extends Error {
  private readonly errors: any
  public status: any
  public path: any
  constructor ({
    message, errors, status, path
  }: IAPIError) {
    super(message)
    this.name = this.constructor.name
    this.message = message
    this.errors = errors
    this.status = status
    this.path = path
    // Error.captureStackTrace(this, this.constructor.name);
  }
}

/**
 * Class representing an API error.
 * @extends ExtendableError
 */
class APIError extends ExtendableError {
  /**
   * Creates an API error.
   * @param {string} message - Error message.
   * @param errors
   * @param path
   * @param {number} status - HTTP status code of error.
   * @param {boolean} isPublic - Whether the message should be visible to user or not.
   */
  constructor ({
    message,
    errors,
    path,
    status,
  }: IAPIError) {
    super({
      message, errors, status, path
    })
  }
}



export default APIError;