import APIError from "./error";

const TIMEOUT = 30000;
export const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

/**
 * All HTTP errors are emitted on this channel for interested listeners
 */
//export const errors = new EventEmitter();

/**
 * GET a path relative to API root url.
 * @param {String}  path Relative path to the configured API endpoint
 * @param {Boolean} token If true, no warning is shown on failed request
 * @param {Object} params if set, is converted to a quey string
 * @param {Boolean} all if true, the request is sent with headers and status
 * @returns {Promise} of response body
 */
export async function get(path: string, params?: any, token?: string) {

  if(params){
    const queryString = toQueryString(params);
    path = path+'?'+queryString;
  }

  return bodyOf(request('get', path, null, token));
}

/**
 * POST JSON to a path relative to API root url
 * @param {String} path Relative path to the configured API endpoint
 * @param {Object} body Anything that you can pass to JSON.stringify
 * @param {Boolean} token If true, no warning is shown on failed request
 * @returns {Promise}  of response body
 */
export async function post(path: string, body: any, token?: string) {
  return bodyOf(request('post', path, body, token));
}

/**
 * PUT JSON to a path relative to API root url
 * @param {String} path Relative path to the configured API endpoint
 * @param {Object} body Anything that you can pass to JSON.stringify
 * @param {Boolean} token If true, no warning is shown on failed request
 * @returns {Promise}  of response body
 */
export async function put(path: string, body: any, token?: string) {
  return bodyOf(request('put', path, body, token));
}

/**
 * DELETE a path relative to API root url
 * @param {String} path Relative path to the configured API endpoint
 * @param {Boolean} token If true, no warning is shown on failed request
 * @returns {Promise}  of response body
 */
export async function del(path: string, token?: string) {
  return bodyOf(request('delete', path, null, token));
}

/**
 * FileUpload a path relative to API root url
 * @param {String} path Relative path to the configured API endpoint
 * @param {Object} body Anything that you can pass to JSON.stringify
 * @param {Boolean} token If true, no warning is shown on failed request
 * @returns {Promise}  of response body
 */
 export async function file(path: string, body: FormData, token?: string) {
  try {
    const endpoint = url(path);
    const headers = getRequestHeaders(body, getAuthenticationToken(token), true);
    
    console.log(endpoint, body, 'file')

    const response = await timeout(fetch(endpoint, {method: 'post', headers, body}), TIMEOUT);

    return bodyOf(handleResponse( path, response));

  } catch (error) {
    if (!token) {
      logError(error, url(path), 'post');
    }
    throw error;
  }
}

/**
 * Make arbitrary fetch request to a path relative to API root url
 * @param {String} method One of: get|post|put|delete
 * @param {String} path Relative path to the configured API endpoint
 * @param {Object} body Anything that you can pass to JSON.stringify
 * @param {Boolean} token If true, no warning is shown on failed request
 */
export async function request(method: string, path: string, body: any, token?: string) {
  try {
    const response = await sendRequest(method, path, body, token);
    return handleResponse(
      path,
      response
    );
  }
  catch (error: any) {
    logError(error, url(path), method);
    throw error;
  }
}

/**
 * Takes a relative path and makes it a full URL to API server
 */
export function url(path: string) {
  const apiRoot = '';// getConfiguration('API_ROOT');
  return apiRoot + path;
}

/**
 * get the token for auth
 */
function getAuthenticationToken(user?: string) {
  return (user)? 'Bearer ' + user : null;
}

/**
 * Constructs and fires a HTTP request
 */
async function sendRequest(method: string, path: string, body: any, token?: string) {

  try {
    const endpoint = url(path);
    const headers = getRequestHeaders(body, getAuthenticationToken(token));
    const options: any = body
      ? {method, headers, body: JSON.stringify(body)}
      : {method, headers};

    console.log(endpoint, method)

    return timeout(fetch(endpoint, options), TIMEOUT);
  } catch (e) {
    throw new Error(e);
  }
}

/**
 * Receives and reads a HTTP response
 */
async function handleResponse(path: string, response: any) {
  const status = response.status;
  const body = await response.clone().json();
  // `fetch` promises resolve even if HTTP status indicates failure. Reroute
  // promise flow control to interpret error responses as failures
  if (status >= 400) {
    //const message = await getErrorMessageSafely(response);
    const error = new APIError({
      ...body,
      path,
    });
    
    // emit events on error channel, one for status-specific errors and other for all errors
    //errors.emit(status.toString(), {path, message: error.message});
    //errors.emit('*', {path, message: error.message}, status);

    throw error;
  }
  
  return {
    status: response.status,
    headers: response.headers,
    body: body ? body : null
  };
}

function getRequestHeaders(body: any, token: string | null, isFile = false ) {
  const header: any = {};
  if (token) {
    header['Authorization'] = token;
  }

  if (isFile) {
    return header;
  }

  if (body) {
    header['Content-Type'] = 'application/json';
  }

  header['Accept'] = 'application/json';
  return header;
}

/**
 * Rejects a promise after `ms` number of milliseconds, it is still pending
 */
function timeout(promise: any, ms: number) {
  return new Promise((resolve, reject) => {
    const timer = setTimeout(() => reject(new Error('timeout')), ms);
    promise
      .then((response: any) => {
        clearTimeout(timer);
        resolve(response);
      })
      .catch(reject);
  });
}

async function bodyOf(requestPromise: any) {
  const response = await requestPromise;
    return response.body;
}

function toQueryString(obj: any, urlEncode?: boolean) {
    
    function flattenObj(x: any, path?: any) {
        const result: any[] = [];

        path = path || [];
        Object.keys(x).forEach(function (key) {
            if (!x[key]) return;

            const newPath: any[] = path.slice();
            newPath.push(key);

            let vals: any[] = [];
            if (typeof x[key] == 'object') {
                vals = flattenObj(x[key], newPath);
            } else {
                vals.push({ path: newPath, val: x[key] });
            }
            vals.forEach(function (obj) {
                return result.push(obj);
            });
        });

        return result;
    } // flattenObj

    // start with  flattening `obj`
    let parts = flattenObj(obj); // [ { path: [ ...parts ], val: ... }, ... ]

    // convert to array notation:
    parts = parts.map(function (varInfo) {
        if (varInfo.path.length === 1) {
          varInfo.path = varInfo.path[0];
        } else {
            const first = varInfo.path[0];
            const rest = varInfo.path.slice(1);
            varInfo.path = first + '[' + rest.join('][') + ']';
        }
        return varInfo;
    }); // parts.map

    // join the parts to a query-string url-component
    const queryString = parts.map(function (varInfo) {
        return varInfo.path + '=' + varInfo.val;
    }).join('&');
    if (urlEncode) return encodeURIComponent(queryString);else return queryString;
}

/**
 * Make best effort to turn a HTTP error or a runtime exception to meaningful error log message
 */
function logError(error: any, endpoint: string, method: any) {
  if (error.status) {
    const summary = `(${error.status} ${error.statusText}): ${error._bodyInit}`;
    console.error(`API request ${method.toUpperCase()} ${endpoint} responded with ${summary}`);
  }
  else {
    console.error(`API request ${method.toUpperCase()} ${endpoint} failed with message "${error.message}"`);
  }
}
