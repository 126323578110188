import storage from 'redux-persist/lib/storage';
import { applyMiddleware, createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';
 
import middleware from './middleware';
import rootReducer from './reducer';
 
const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['auth']
};
 
export const store = createStore(
  persistReducer(persistConfig, rootReducer),
  composeWithDevTools(applyMiddleware(...middleware))
);

export const persistor = persistStore(store);