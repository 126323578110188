/**
 * Asynchronously loads the component for HomePage
 */

import * as React from "react";
import loadable from "src/helpers/loadable";
import LoadingIndicator from "src/components/LoadingIndicator";

export default loadable(() => import("./index"), {
  fallback: <LoadingIndicator />
});
