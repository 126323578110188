import AppActions, { Actions, IUserForm, IReducer } from 'src/redux/action';
import { post, file } from 'src/helpers/api';
import { API_URL } from 'src/constants/urls';

export interface IAuthUser extends IUserForm {
    step: number;
    password: string;
}

export interface IAuthReducer extends IReducer {
    user: IAuthUser;
    alreadyLoggedIn: boolean | null;
}

export default class AuthActions extends Actions {
    public static ACTIONS = {
        INIT: 'AUTH_INIT',
        FALIURE: 'ERROR_AUTH',
        LOGIN: 'LOGIN',
        REGISTER: 'REGISTER',
        ALREADY_LOGGED_IN: 'ALREADY_LOGGED_IN',
        SET_USER_DETAILS: 'SET_USER_DETAILS',
    };
  
    public static login(email: string, password: string)  {
        return async (dispatch: any) => {
            try {
                await dispatch(AuthActions.init({isSubmitting: true}));
                const res = await post(API_URL+'auth/login', {
                    email,
                    password
                });
    
                await dispatch(AuthActions.success(res));
            } catch (err) {
                dispatch(AuthActions.failure(err));
            }
        };
    }

    public static register(data: IAuthUser) {
        return async (dispatch: any) => {
            try {
                await dispatch(AuthActions.init({isSubmitting: true}));

                const formData = new FormData();
                const values: any = {...data};

                delete values.step;
                delete values.confirm;

                for (const [key, value] of Object.entries(values)) {
                    formData.append(key, value as any);
                }

                const res = await file(API_URL+'auth/register', formData);
                
                await dispatch(AuthActions.success(res));
            } catch (err) {
                dispatch(AuthActions.failure(err));
            }
        };
    }

    public static updateUser(data: any) {
        return async (dispatch: any, getState: any) => {
            try {
                await dispatch({
                    type: AuthActions.ACTIONS.SET_USER_DETAILS,
                    payload: data
                });
    
                const { auth } = getState();
                if( data.step === 3) {
                    await dispatch({
                        type: AuthActions.ACTIONS.SET_USER_DETAILS,
                        payload: {step: 0}
                    });
                    await dispatch(AuthActions.register(auth.user));
                }
            } catch (err) {
                dispatch(AuthActions.failure(err));
            }
        };
    }

    public static success( res: any ) {
        return async (dispatch: any) => {
            try {
                await dispatch({
                    type: AppActions.ACTIONS.LOGIN,
                    payload:  res
                });

                dispatch({
                    type: AuthActions.ACTIONS.LOGIN
                });
            } catch (err) {
                dispatch(AuthActions.failure(err));
            }
        };
    }

    public static checkLoginState() {
        return async (dispatch: any, getState: any) => {
            await dispatch(AuthActions.init({alreadyLoggedIn: null}));

            const { app: { token } } = getState();
            if (!token) {
                return dispatch(AuthActions.init({alreadyLoggedIn: false}));
            }

            dispatch({
                type: AuthActions.ACTIONS.ALREADY_LOGGED_IN,
                payload: true,
            });
        };
    }
}